import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import News from "../../Home/NewsAndEvents/News";
import config from "../../../../config";
import SpinnerCom from "../../../Spinner/Spinner";

const NewsEvents = () => {
  const [newsevents, setNewsevents] = useState([]);
  const [loading, setLoading] = useState(true);

 
  useEffect(async () => {
    const newseventsAPI=`${config.apiUrlLaravel}/newsevents`;
    axios.get(newseventsAPI)
    .then(res=> {
      setNewsevents(res.data.newsevents);
      setLoading(false);
    })
    .catch(err=> console.log(err))
  }, []);

  document.title = "News and Events || CSE";

  const newsEvents= newsevents.map(item =>{
    return (
        <News item={item} key={Math.random()} />
    )
  })
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div>
            <Card.Title className="title">News and Events</Card.Title>
            <hr className="hr_style" />

              {loading? <SpinnerCom/> :
                   <div className="row">
                   {newsEvents}
                   </div>
                  }
          
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NewsEvents;
