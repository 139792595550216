import React, {useEffect, useState} from "react";
import { Card, Container } from "react-bootstrap";
import PublicationItem from "./PublicationItem";


const Publications = () => {
  document.title = "Publications || CSE";



  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div>
            <Card.Title className="title">All Publications</Card.Title>
            <hr className="hr_style" />
           

           <PublicationItem />
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Publications;
