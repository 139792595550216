import React from "react";
import { Carousel, Container } from "react-bootstrap";
import classes from "./Slider.module.css";
import config from "../../../../config";

const Slider = (props) => {
  return (
      <Carousel>
        {props.slider.map((item) => (
          <Carousel.Item key={item.image}>
            <img
              className="img-slider img-fluid"
              //src={item.image}
              src={config.apiUrlImage+"/"+item.image}
              alt="First slide"
            />
            <Carousel.Caption>
              <div className={item.title ? classes.caption_box : ""}>
                <h3 className={classes.slider_title}>
                  {item.title ? item.title : ""}
                </h3>
                <p>{item.sub_title ? item.sub_title : ""}</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
  );
};

export default Slider;
