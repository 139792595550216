import dateFormat from "dateformat";
import React, {useEffect, useState} from "react";
import { Badge, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./notice.css";
import axios from "axios";
import config from "../../../../config";
import SpinnerCom from "../../../Spinner/Spinner";


const Notice = () => {
  const [notices, setNotices] = useState([]);
   const [loading, setLoading] = useState(true);



  const noticesData = () => {
    axios
      .get(`${config.apiUrlLaravel}/notices`)
      .then((res) => {
        setNotices(res.data.notices);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setNotices([]);
      });
  };
  useEffect(async () => noticesData(), []);

  return loading?  <SpinnerCom />: (
    <div className="row">
      {notices.map((item) => (
        <div className="col-md-6" key={Math.random()}>
          <ListGroup>
            <Link to={`/announcement/notices/${item.id}`} className="link_notice">
              <ListGroup.Item action>
                <Badge bg="success" pill className="p-2">
                  {dateFormat(item.publication_date, "mediumDate")}
                </Badge>
                <span className="notice_title, ms-2">{item.title}</span>
              </ListGroup.Item>
            </Link>
          </ListGroup>
        </div>
      ))}
    </div>
  );
};

export default Notice;
