import React, { Component } from "react";
import { Card, Container } from "react-bootstrap";

class Cisco extends Component {

  render() {
    document.title = "Cisco Academy || CSE";
    return (
      <Container className="mt-3">
        <Card>
          <Card.Body>
            <div data-aos="fade-down">
              <Card.Title  className="title">Cisco Academy</Card.Title>
              <hr  className="hr_style"/>

              <Card.Text  className="text-justify">
                Coming soon
              </Card.Text>


              </div>


          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default Cisco;
