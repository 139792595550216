import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import config from "../../../config";


const PeopleItem = (props)=>{
    var email = props.item.email;
    var username   = email.substring(0, email.lastIndexOf("@"));

    // if(props.item.type=='Faculty'){
    //     var faculty= <div className="col-md-12">
    //             <b style={{fontSize: "12px"}}>Field of Specialization:</b>
    //             <p style={{fontSize: "12px"}}>{props.item.field_specialist}</p>
    //         </div>
    // }

    return (
        <div className="col-md-6 mt-2 ">
                <Card className="shadow p-2 ">
                <div className="row">
                  <div className="col-md-5">
                    <img
                      src={config.apiUrlImage+"/"+ props.item.image}
                      height="170px"
                      width="150px"
                      alt=""
                      className="rounded mx-auto d-block"
                    />
                  </div>
                    <div className="col-md-7">
                        <h6>{props.item.name_english}  {props.item.status!=='Active'? <span className="text-danger">({props.item.status})</span>: '' }</h6>
                        <h6 className="m-0">{props.item.name_bangla}</h6>
                        <p className="m-1">{props.item.designation.name}</p>
                         <strong><i className="fa  fa-graduation-cap"
                                   style={{marginRight: "10px"}}></i></strong>{props.item.education} <br/>
                        <strong><i className="fa  fa-phone-square"
                                   style={{marginRight: "10px"}}></i></strong>{props.item.phone} <br/>
                        <strong><i className="fa  fa-phone-square"
                                   style={{marginRight: "10px"}}></i></strong>{props.item.mobile} <br/>
                        <strong><i className="fa  fa-envelope-square"
                                   style={{marginRight: "10px"}}></i></strong> {props.item.email}
                        <br/>

                        {
                            props.item.type ==='Officer' ? '':  <Link to={{pathname: "https://profile.duet.ac.bd/u/" + username}} rel="noopener noreferrer"
                              className="btn btn-sm btn-info mt-3" target="_blank">
                            Profile
                        </Link>
                        }

                    </div>
                    {/*{faculty}*/}


                </div>
                </Card>
              </div>
    );
}

export default PeopleItem;