import dateFormat from "dateformat";
import React, {useState} from "react";
import {Button, Card, Col, Modal} from "react-bootstrap";
import { Link } from "react-router-dom";
import classes from "./News.module.css";
import config from "../../../../config";

const Achievement = (props) => {

   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Col md={6} >
      <Card className="m-2 h-100">
        <Card.Img variant="top" className="card-img-top" src={config.apiUrlImage+"/"+props.item.image}/>
        <Card.Body>
          <Card.Text>
            {/*to={`/achievement/${props.item.id}`}*/}
            <Link  to="#" onClick={handleShow} className={classes.news_element_title}>
             {props.item.title}
            </Link>
          </Card.Text>
          <Card.Text>{dateFormat(props.item.publication_date, "mediumDate")}</Card.Text>
        </Card.Body>
      </Card>


      <Modal show={show} onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.item.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <img src={config.apiUrlImage+"/"+props.item.image} alt="news" width="100%" />

         <i> Publication: </i> <b>{dateFormat(props.item.date, "mediumDate")}</b>



      <br/>
          <div dangerouslySetInnerHTML={{ __html: props.item.description }}></div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default Achievement;
