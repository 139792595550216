import React   from "react";
import {Spinner} from "react-bootstrap";
import './spinner.css';

const SpinnerCom= () =>{
    return(
        <div className="child">
            <center><Spinner animation="border" variant="success"/></center>
        </div>
    )
}

export default SpinnerCom;