import React, {useEffect, useState} from "react";
import {Card, Container, Accordion} from "react-bootstrap";
import MOU from  '../../../../assets/img/mou.jpg';
import config from "../../../../config";
import dateFormat from "dateformat";
import classes from "../../ Facilities/facilities.module.css";
const Training = () => {
    return (
       <Container className="mt-3">
      <Card>
           <Card.Title >Digital Skills for Students Organized by  CSE, DUET, Gazipur and EDGE, BCC, ICT Ministry </Card.Title>

        <Card.Body>
          <div className="row text-center justify-content-center">
              <div className="col-md-8">
                <div className={classes.img_container}>
                  <img
                   src={MOU}
                    alt="Avatar"
                    className={[classes.image, "rounded"].join(" ")}
                  />
                  <div className={classes.overlay}>MOU with CSE, DUET, Gazipur and EDGE, BCC, ICT Ministry</div>
                </div>
              </div>



            </div>

            <div className="mt-4">
                 <p className="text-justify">The Dept. of CSE, DUET will conduct midterm and final assessments. Upon successful completion of the program, and based on the final evaluation (with at least and 60% score and 80% attendance), each participant will be jointly awarded a certificate by the Public Department/Institute/Center/Universities and EDGE. Number of trainees and expenditure will be counted based on the successful completion of training. Overall assessment of trainee performance will be based on the following score distribution (indicative):</p>

                <ul>
                    <li>Class attendance: 10%</li>
                    <li>Quiz and Assignment(s): 20%</li>
                    <li>Mid-term assessment: 20%</li>
                    <li>Project: 25%</li>
                    <li>Final Evaluation: 25%</li>
                </ul>

                <h5>Eligibility Criteria for Trainees:</h5>
                <ul>
                    <li> <b>Foundation Skills Program: </b> Candidates below 35 years of age who completed their higher secondary education.</li>
                    <li> <b>Intermediate level training: </b> Candidates under 35 years of age who are either enrolled in or have completed their tertiary education in any discipline.</li>
                    <li> <b>Advanced level training:</b> Candidates below 35 years of age with foundational skills in IT and a background in science and engineering.</li>
                </ul>
            </div>

           <div className="mt-5">
               <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header> <b>Foundation Skills Program </b></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Basic Network Administration</li>
                        <li>Computer Hardware</li>
                        <li>Digital marketing</li>
                        <li>Graphics desgin (Photoshop & AI Tools usage)</li>
                        <li>Microsoft word, excel</li>
                        <li>Microsoft Word, Powerpoint</li>
                        <li>Search Engine Optimization</li>
                        <li>Software testing</li>
                        <li>Basic Web Development</li>
                        <li>Basic programming with Python</li>
                        <li>Video Production and Editing</li>

                    </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header><b>Intermediate Level Training</b></Accordion.Header>
                    <Accordion.Body>
                          <li>Mobile app development (Android/ Flutter/IOS)</li>
                            <li>C#.NET</li>
                            <li>Database (MySQL / Oracle / SQL Server)</li>
                            <li>Front-End Developer (React / NodeJS / VueJS/ Angular JS)</li>
                            <li>Java (Any popular framework)</li>
                            <li>PHP(Laravel)</li>
                            <li>Python (Django)</li>
                            <li>Quality Assurance & Testing</li>
                            <li>User Experience (UX) Design</li>
                            <li>Wordpress</li>

                    </Accordion.Body>
                  </Accordion.Item>
            </Accordion>
           </div>



        </Card.Body>
      </Card>
    </Container>
    );
};

export default Training;