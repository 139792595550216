import React, {useEffect, useState} from "react";
import { Badge, Card, Container } from "react-bootstrap";
import sorwar from "../../../../assets/img/sorwar.jpg";
import seraj from "../../../../assets/img/Seraj.jpg";
import axios from "axios";
import config from "../../../../config";
import PeopleItem from "../PeopleItem";
import SpinnerCom from "../../../Spinner/Spinner";

const Officer = () => {
  document.title = "Offices || CSE";

  const [people, setPeople] = useState([]);
   const [loading, setLoading] = useState(true);
   const peopleData = () => {
    axios
      .get(`${config.apiUrlLaravel}/people/officer`)
      .then((res) => {
          setPeople(res.data.people);
          setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPeople([]);
      });
  };
     useEffect(async () => {
    peopleData();

  }, []);

     const peopleItem=people.map((item)=>{
    return (
        <PeopleItem item={item} key={Math.random()} />
    )
  });
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>

                <div>
                    <Card.Title className="title">Officers List</Card.Title>
                    <hr className="hr_style"/>

                    {loading ? <SpinnerCom/> :
                    <div className="row">
                        {peopleItem}
                    </div>
                    }
                </div>

        </Card.Body>
      </Card>
    </Container>
  );
};

export default Officer;
