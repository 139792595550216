import React, {useEffect, useState} from "react";
import {Card, Container, ListGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import axios from "axios";
import config from "../../../../config";
import SpinnerCom from "../../../Spinner/Spinner";

const PG = () => {
  document.title = "PG || CSE";
  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(true);

  const downloadData = () => {
    axios
        .get(`${config.apiUrlLaravel}/academics/PG_C`)
        .then((res) => {
            setDownloads(res.data.academics);
            setLoading(false)

        })
        .catch((err) => {
          console.log(err);
          setDownloads([]);
        });
  };
  useEffect(async () => {
    downloadData();
  }, []);

  let downloadItem = (item, index) => {
    return (
        <tr key={Math.random()}>
          <th scope="row">{index + 1} </th>
          <td className="links">
             <a  href={config.apiUrlImage + "/" + item.file} target="_blank" download >{item.title}</a>
          </td>
              <th>

            <a href={config.apiUrlImage + "/" + item.file} target="_blank" className="btn btn-primary btn-sm" download>
              Download
            </a>
          </th>
        </tr>
    )
  }

  const downloadItems = downloads.map((item, index) => {
    return downloadItem(item, index)
  });
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div>
            <Card.Title className="title">Postgraduate Curriculum</Card.Title>
            <hr className="hr_style" />
              <div className="row justify-content-center">
                  <div className="col-md-10 ">
                      <table className="table table-striped table-bordered text-center">
                          <thead>
                          <tr>
                              <th scope="col" width="20%">
                                  SL
                              </th>
                              <th scope="col">Name of Curriculum</th>
                              <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          {loading? <SpinnerCom/>: downloadItems}

                          </tbody>
                      </table>
                  </div>
              </div>
           
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PG;
