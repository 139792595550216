import dateFormat from "dateformat";
import React, {useEffect, useState} from "react";
import {Badge, Card, Container, ListGroup} from "react-bootstrap";
import { withRouter } from "react-router";
import "./notice.css";
import axios from "axios";
import config from "../../../../config";
import SpinnerCom from "../../../Spinner/Spinner";



const NoticeDetail = (props) => {

  // const [notice, setNotice] = useState("");
   const [loading, setLoading] = useState(true);
    const [notices, setNotices] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);



  const noticeDetail = () => {
    axios
      .get(`${config.apiUrlLaravel}/notices/ ${props.match.params.id}`)
      .then((res) =>{
          setSelectedItem(res.data.notice);
           setLoading(false);
      } )
      .catch((err) => {
        console.log(err);
        setSelectedItem(null);
      });
  };

  const noticesData = () => {
    axios
      .get(`${config.apiUrlLaravel}/notices`)
      .then((res) => {
        setNotices(res.data.notices);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setNotices([]);
      });
  };

  useEffect(async () => {
      noticeDetail();
      noticesData();
  },[]);



  return loading? <SpinnerCom />: (
    <Container className="mt-3">
       <div className="row">
           <div className="col-md-3">
            <Card>
                   <Card.Body>
                       <Card.Title className="title">All Notices</Card.Title>
                       <hr className="hr_style"/>
                       {notices.map((item) => (
                           <div className="col-md-12" key={Math.random()}>
                               <ListGroup>
                                   {/*<Link to={`/announcement/notices/${item.id}`} className="link_notice">*/}
                                       <ListGroup.Item action
                                       active={item===selectedItem}
                                                       onClick={()=>setSelectedItem(item)}
                                       >
                                           <Badge bg="success" pill className="p-2">
                                               {dateFormat(item.publication_date, "mediumDate")}
                                           </Badge>
                                           <span className="notice_title, ms-2">{item.title}</span>
                                       </ListGroup.Item>
                                   {/*</Link>*/}
                               </ListGroup>
                           </div>
                       ))}

                   </Card.Body>
               </Card>
           </div>
           <div className="col-md-9">
               <Card>
                   <Card.Body>
                       <Card.Title className="title">Notice Detail</Card.Title>
                       <hr className="hr_style"/>
                       {selectedItem? (
                           <div>
                               <Badge bg="success" pill className="p-2">
                                   {dateFormat(selectedItem.publication_date, "mediumDate")}
                               </Badge>
                               <Card.Title>{selectedItem.title}</Card.Title>

                               <a href={config.apiUrlImage + "/notices/" + selectedItem.file} download>Download</a>
                               <br/>

                               <embed src={config.apiUrlImage + "/notices/" + selectedItem.file} type="application/pdf"
                                      width="100%"
                                      height="600"/>
                           </div>

                       ): null}


                   </Card.Body>
               </Card>
           </div>

       </div>

    </Container>
  );
};

export default withRouter(NoticeDetail);
