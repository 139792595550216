import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { withRouter } from "react-router";
import config from "../../../../../config";
import dateFormat from "dateformat";

const AchievemnetDetail = (props) => {
 
  const [achievement, setAchievement] = useState("");

  const achievementsDetail = () => {
    axios
      .get(`${config.apiUrlLaravel}/achievements/ ${props.match.params.id}`)
      .then((res) => setAchievement(res.data.achievement))
      .catch((err) => {
        console.log(err);
        setAchievement("");
      });
  };
  useEffect(() => achievementsDetail(), []);
  
  document.title = achievement.title;

  return (
    <Container className="mt-3">
      <Card>
          <div className='text-center'>
               <Card.Img variant="top" className="card-img-detail" src={config.apiUrlImage+"/"+achievement.image} alt="news"  />
          </div>

        <Card.Body>
        <Card.Title>{achievement.title}</Card.Title>
            <i> Publication: </i> <b>{dateFormat(achievement.publication_date, "mediumDate")}</b>
            <br/>
          <Card.Text>{achievement.description}</Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default withRouter(AchievemnetDetail);
