import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import axios from "axios";
import dateFormat from "dateformat";
import config from "../../../../config";

const PublicationItem = () => {

    const [publicationItems, setPublicationsItems] = useState([]);
    const [filterPublicationItems, setFilterPublicationsItems] = useState([]);
    const [search, setSearch] = useState("");
    const [pending, setPending] = useState(true);

  const getPublication = async () => {
      try {
          //const response = await axios.get('https://restcountries.com/v2/all');
          const response = await axios.get(`${config.apiUrlLaravel}/publications`);
           const filteredData = response.data.publications.filter(item => item.department==='CSE');
            const sortedData = filteredData.sort((a, b) => {
          return new Date(b.year) - new Date(a.year);
        });
           setPublicationsItems(sortedData)
            setFilterPublicationsItems(sortedData)
            setPending(false);


      }catch (error){
          console.log(error);
      }
  }



  useEffect(()=>{
      getPublication();
  },[])


    useEffect(() =>{
        const result= publicationItems.filter(item => {
            return (item.title.toLowerCase().match(search.toLowerCase()) || (item.author.toLowerCase().match(search.toLowerCase())));
        })
        setFilterPublicationsItems(result);
    },[search]);



  const columns = [
      {
          name: 'Title',
          selector: row => row.title,
          sortable: true,
          width: '50%',
          wrap: true

      },
      {
          name: 'Author',
          selector: row => row.author,
           width: '20%',
          wrap: true
      },
      {
          name: 'Type',
          selector: row => row.paper_type,
           width: '10%'
      },
       {
          name: 'Date',
          selector: row => dateFormat(row.year, "mediumDate"),
           width: '10%'
      },
       {
          name: 'Details',
           cell: row  => <a href={row.publication_link}  target="_blank" rel="noopener noreferrer" >Click</a>,

           width: '10%'
      },


      //  {
      //     name: 'Flag',
      //     selector: row =>  <img width={50} height={50} src={row.flag}/>,
      // },

    ];
    return (
        <DataTable
            columns={columns}
            data={filterPublicationItems}
            progressPending={pending}
            responsive={true}
            pagination
            // fixedHeader
            // fixedHeaderScrollHeight="450px"
            highlightOnHover
            subHeader
            subHeaderComponent={
            <input
                type='text'
                placeholder="Search here title or author"
                className="form-control w-25"
                value={search}
                onChange={(e)=> setSearch(e.target.value)}
            />}
        />
    )

};

export default PublicationItem;
