import React, {useEffect, useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import Logo from "../../assets/img/CSE-Web-site-logo-2.png";
import classes from "./Header.module.css";


const Header = () => {

    const [showAbout, setShowAbout] = useState(false);
    const [showFacilities, setShowFacilities] = useState(false);
    const [showAcademic, setShowAcademic] = useState(false);
    const [showPeople, setShowPeople] = useState(false);
    const [showResearch, setShowResearch] = useState(false);
    const [showNotice, setShowNotice] = useState(false);
    const [showOther, setShowOther] = useState(false);

    const showDropdownAbout = (e) => {
        setShowAbout(!showAbout);
    }
    const hideDropdownAbout = e => {
        setShowAbout(false);
    }

    const showDropdownFacilities = (e) => {
        setShowFacilities(!showFacilities);
    }
    const hideDropdownFacilities = e => {
        setShowFacilities(false);
    }
    const showDropdownAcademic = (e) => {
        setShowAcademic(!showAcademic);
    }
    const hideDropdownAcademic = e => {
        setShowAcademic(false);
    }

    const showDropdownPeople = (e) => {
        setShowPeople(!showPeople);
    }
    const hideDropdownPeople = e => {
        setShowPeople(false);
    }

      const showDropdownResearch = (e) => {
        setShowResearch(!showResearch);
    }
    const hideDropdownResearch = e => {
        setShowResearch(false);
    }
      const showDropdownNotice = (e) => {
        setShowNotice(!showNotice);
    }
    const hideDropdownNotice = e => {
        setShowNotice(false);
    }
      const showDropdownOther = (e) => {
        setShowOther(!showOther);
    }
    const hideDropdownOther = e => {
        setShowOther(false);
    }

    const handleCollapse = () => {

    var nav = document.getElementById("navbarNav");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };


    return (

        <Navbar
            className={[classes.header_manu, "container-xxl"].join(' ')}
            expand="lg"
            sticky="top"

        >
            <Container fluid="xxl" className={classes.header_st}>
                <LinkContainer to="/">
                    <Navbar.Brand>
                        <img
                            src={Logo}
                            className={["img-fluid", classes.cseLogo].join(' ')}
                            alt="This is logo"
                        />
                    </Navbar.Brand>

                </LinkContainer>

                <Navbar.Toggle   aria-controls="navbarNav" id="navbarBtn"  />
                <Navbar.Collapse  id="navbarNav">
                    <Nav className="ms-auto">
                        <LinkContainer to="/" onClick={()=>handleCollapse()}>
                            <Nav.Link className={classes.links}  >Home</Nav.Link>
                        </LinkContainer>

                        <NavDropdown
                            className={classes.links}
                            title="About"
                            id="about-nav-dropdown"
                            show={showAbout}
                            onMouseEnter={showDropdownAbout}
                            onMouseLeave={hideDropdownAbout}
                        >
                            <LinkContainer to="/about-of-cse" exact onClick={()=>handleCollapse()} >
                                <NavDropdown.Item>About of CSE</NavDropdown.Item>
                            </LinkContainer>

                        </NavDropdown>

                        <NavDropdown
                            className={classes.links}
                            title="Facilities"
                            id="about-nav-dropdown"
                            show={showFacilities}
                            onMouseEnter={showDropdownFacilities}
                            onMouseLeave={hideDropdownFacilities}

                        >
                            <LinkContainer to="/facilities/classrooms"  exact onClick={()=>handleCollapse()} >
                                <NavDropdown.Item >Classrooms</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/facilities/labs" onClick={()=>handleCollapse()} >
                                <NavDropdown.Item> Laboratories</NavDropdown.Item>
                            </LinkContainer>

                             <LinkContainer to="/facilities/thesis_lab" onClick={()=>handleCollapse()} >
                                <NavDropdown.Item>Thesis and Research Lab </NavDropdown.Item>
                            </LinkContainer>

                             <LinkContainer to="/facilities/crts" onClick={()=>handleCollapse()} >
                                <NavDropdown.Item>CRTS</NavDropdown.Item>
                            </LinkContainer>



                        </NavDropdown>

                        <NavDropdown
                            className={classes.links}
                            title="Academic"
                            id="academic-nav-dropdown"

                            show={showAcademic}
                            onMouseEnter={showDropdownAcademic}
                            onMouseLeave={hideDropdownAcademic}
                        >
                            <LinkContainer to="/academic/ug-curriculum" onClick={()=>handleCollapse()}>
                                <NavDropdown.Item>Undergraduate Curriculum </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/academic/pg-curriculum" onClick={()=>handleCollapse()}>
                                <NavDropdown.Item>Postgraduate Curriculum </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/academic/calender" onClick={()=>handleCollapse()}>
                                <NavDropdown.Item>Academic Calender</NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/academic/class-routine" onClick={()=>handleCollapse()}>
                                <NavDropdown.Item>Class Routine</NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/academic/classrep" exact onClick={()=>handleCollapse()}>
                                <NavDropdown.Item>Class Representative</NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/academic/admission" onClick={()=>handleCollapse()}>
                                <NavDropdown.Item>Admission</NavDropdown.Item>
                            </LinkContainer>

                        </NavDropdown>

                        <NavDropdown
                            className={classes.links}
                            title="People"
                            id="people-nav-dropdown"
                            show={showPeople}
                            onMouseEnter={showDropdownPeople}
                            onMouseLeave={hideDropdownPeople}
                        >
                            <LinkContainer to="/people/honerable-heads">
                                <NavDropdown.Item>
                                    Honorable Head
                                </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/people/faculty-members">
                                <NavDropdown.Item>
                                    Faculty Members
                                </NavDropdown.Item>
                            </LinkContainer>


                            <LinkContainer to="/people/officers">
                                <NavDropdown.Item>Officers</NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/people/staffs">
                                <NavDropdown.Item>Staffs</NavDropdown.Item>
                            </LinkContainer>

                        </NavDropdown>

                        <NavDropdown
                            className={classes.links}
                            title="Research"
                            id="research-nav-dropdown"
                            show={showResearch}
                            onMouseEnter={showDropdownResearch}
                            onMouseLeave={hideDropdownResearch}
                        >
                            <LinkContainer to="/research/publications">
                                <NavDropdown.Item>
                                    Publications
                                </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/research/MajorResearchAreas">
                                <NavDropdown.Item>
                                    Major Research Areas
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>

                        <NavDropdown
                            className={classes.links}
                            title="Announcement"
                            id="anno-nav-dropdown"
                             show={showNotice}
                            onMouseEnter={showDropdownNotice}
                            onMouseLeave={hideDropdownNotice}
                        >
                            <LinkContainer to="/announcement/notices">
                                <NavDropdown.Item>
                                    Notice Board
                                </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/announcement/newsandevents">
                                <NavDropdown.Item>
                                    News and Events
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/achievements">
                                <NavDropdown.Item>Achievements</NavDropdown.Item>
                            </LinkContainer>

                        </NavDropdown>



                        <LinkContainer to="/alumni">
                            <Nav.Link className={classes.links}>
                                Alumni
                            </Nav.Link>
                        </LinkContainer>
                        <NavDropdown
                            className={classes.links}
                            title="Others"
                            id="about-nav-dropdown"
                            align="end"
                             show={showOther}
                            onMouseEnter={showDropdownOther}
                            onMouseLeave={hideDropdownOther}
                        >
                            <LinkContainer to="/gallery" exact>
                                <NavDropdown.Item>Gallery</NavDropdown.Item>
                            </LinkContainer>
                              <LinkContainer to="/downloads-form">
                                <NavDropdown.Item> Downloadable Form</NavDropdown.Item>
                            </LinkContainer>
                               <LinkContainer to="/facilities/duetcs">
                                <NavDropdown.Item> DUET Computer Society (DUETCS)</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/others/cisco" exact>
                                <NavDropdown.Item>CISCO Academy</NavDropdown.Item>
                            </LinkContainer>
                               <LinkContainer to="/training" exact>
                                <NavDropdown.Item>Training with EDGE, BCC</NavDropdown.Item>
                            </LinkContainer>
                            <a href="https://confncim.com/" className="dropdown-item" target="_blank" rel="noopener noreferrer" >NCIM 2023</a>

                        </NavDropdown>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>



    );
};

export default Header;
