import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";

const Footer = () => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <footer>
      <hr/>
      <Container className="py-3">
        <Row className={classes.links}>
          <Col md={4}>
            <h6 className={classes.title_heading}>Quicklinks</h6>
            <Link to={{ pathname: "https://www.duet.ac.bd/" }} target="_blank">
              DUET Website
            </Link>
            <br />
            <Link to={{ pathname: "https://elp.duetbd.org/" }} target="_blank">
              E-Learning Platform
            </Link>
            <br />
            <Link to={{ pathname: "http://ugr.duetbd.org/" }} target="_blank">
              UGR
            </Link>
            <br />
            <Link
              to={{ pathname: "http://www.pgr.duetbd.org/" }}
              target="_blank"
            >
              PGR
            </Link>
            <br />
            <Link
              to={{ pathname: "https://profile.duet.ac.bd/login" }}
              target="_blank"
            >
              OBE-Base Profile Management System
            </Link>
            <br />
            <Link
              to={{ pathname: "http://www.duetpayroll.org/" }}
              target="_blank"
            >
              DUET Payroll
            </Link>
            <br />
            <Link
              to={{ pathname: "http://transport.duetbd.org" }}
              target="_blank"
            >
              Vehicle Requisition System
            </Link>
            <br />
          </Col>
          <Col md={5}>
            <h6 className={classes.title_heading}>Contact</h6>
            <address className={classes.address}>
              Room# 302, Old Academic Building <br />
              Department of Computer Science and Engineering <br />
              Dhaka University of Engineering & Technology, Gazipur <br />
              Gazipur-1700 <br />
              Phone : +88-02-9204711, +88-02-9204734-53, Ext. 3201 (Off.) <br />
              Fax : +88-02-9204701, +88-02-9204702 <br />
              E-mail: head_cse@duet.ac.bd <br />
            </address>
          </Col>
          <Col md={3}>
            <h6 className={classes.title_heading}> Information</h6>
            <Link to="/academic/ug-curriculum">
              Curriculum Undergraduate
            </Link>
            <br />
            <Link to="/academic/pg-curriculum">
              Curriculum Postgraduate
            </Link>
            <br />
            <Link to="/announcement/notices">Notice</Link> <br />
            <Link to="/announcement/newsandevents">News and Events</Link> <br />
            <Link to="/downloads-form">Downloads Form</Link> <br />
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col className="text-center mb-3">
            Copyright © {new Date().getFullYear()} Department of Computer Science and Engineering,
            DUET
            <br/>
            Design and Developed by Engr. Md. Sorwar Alam, Assistant Programmer, Dept of CSE
          </Col>
        </Row>
      </Container>
      {showButton && (
        <span className={classes.back_to_top}>
          <i className="fa fa-chevron-up" onClick={scrollToTop}></i>
        </span>
      )}
    </footer>
  );
};

export default Footer;
