import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Achievement from "./Achievement";
import classes from "./News.module.css";

const Achievements = (props) => {

  const achievements= props.achievements.slice(0, 2).map(item =>{
    return (
        <Achievement item={item} key={Math.random()} />
    )
  })

  return (
      <Card data-aos="fade-up">
        <Card.Header>
          <Row>
            <Col md={8}>
              <h6 className={classes.title_news}>Achievements</h6>
            </Col>
            <Col md={4}>
              <Link
                to="/achievements"
                className={[
                  "d-flex justify-content-end",
                  classes.all_news,
                ].join(" ")}
              >
                See All >>
              </Link>
            </Col>
          </Row>
        </Card.Header>

        <Row className="my-3">
         {achievements}
        </Row>
      </Card>
  );
};

export default Achievements;
