import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import News from "./News";
import classes from "./News.module.css";

const NewsAndEvents = (props) => {

 const newsEvents= props.newsEvents.slice(0, 2).map(item =>{
    return (
        <News item={item} key={Math.random()} />
    )
  })

  return (

      <Card data-aos="fade-up">
        <Card.Header>
          <Row>
            <Col md={8}>
              <h6 className={classes.title_news}>News and Events</h6>
            </Col>
            <Col md={4}>
              <Link
                to="/announcement/newsandevents"
                className={[
                  "d-flex justify-content-end",
                  classes.all_news,
                ].join(" ")}
              >
                See All >>
              </Link>
            </Col>
          </Row>
        </Card.Header>

        <Row className="my-3">
          
                {newsEvents}
        </Row>
      </Card>
  );
};

export default NewsAndEvents;
