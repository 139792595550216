import React, {useEffect, useState} from "react";
import {Badge, Card, Container, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import Obidur from "../../../../assets/img/obaidur-239x300.jpg";
import Sid from "../../../../assets/img/sid.png";
import './faculty.css';
import axios from "axios";
import config from "../../../../config";
import Head from "../HonerHead/Head";
import PeopleItem from "../PeopleItem";
import SpinnerCom from "../../../Spinner/Spinner";

const Faculty = () => {
    document.title = "Faculty Members || CSE";

    const [people, setPeople] = useState([]);
    const [filterPeople, setFilterPeople] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const peopleData = () => {
        axios
            .get(`${config.apiUrlLaravel}/people/faculty`)
            .then((res) => {
                    setPeople(res.data.people);
                    setFilterPeople(res.data.people);
                    setLoading(false);
                }
            )
            .catch((err) => {
                console.log(err);
                setPeople([]);
            });
    };
    useEffect(async () => {
        peopleData();

    }, []);


    useEffect(() => {
        const result = people.filter(item => {
            return (
                (item.name_english && item.name_english.toLowerCase().includes(search.toLowerCase()))
            )
        });

        setFilterPeople(result);
    }, [search]);

    const peopleItem = filterPeople.map((item) => {
        return (
            <PeopleItem item={item} key={Math.random()}/>
        )
    });

    const totalProfessor=people.filter(item=> item.designation.name==='Professor').length;
    const totalInServiceProfessor=people.filter(item=> item.designation.name==='Professor' && item.status === 'Active').length;
    console.log(totalInServiceProfessor);

    return (
        <Container className="mt-3">
            <Card>
                <Card.Body>
                    <div>
                        <Card.Title className="title">Faculty Summary</Card.Title>
                        <hr className="hr_style"/>
                        <div className=" row justify-content-center">
                            <div className="col-md-6">
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <td></td>
                                        <td>In Total</td>
                                        <td>In Service</td>
                                        <td>On Study Leave</td>
                                        <td>On Leave</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Professor</td>
                                        <td>8</td>
                                        <td>7</td>
                                        <td>0</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td>Associate Professor</td>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>Assistant Professor</td>
                                        <td>15</td>
                                        <td>7</td>
                                        <td>8</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>Lecturer</td>
                                        <td>7</td>
                                        <td>5</td>
                                        <td>2</td>
                                        <td>0</td>
                                    </tr>

                                    <tr>
                                        <th>Total</th>
                                        <th>32</th>
                                        <th>21</th>
                                        <th>10</th>
                                        <th>1</th>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <Card.Title className="title">Faculty List</Card.Title>
                        <hr className="hr_style"/>
                        {loading ? <SpinnerCom/> : <div>

                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <input
                                            type='text'
                                            placeholder="Search here Name"
                                            className="form-control"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </Form.Group>
                                </div>

                            </div>

                            <div className="row">

                                {peopleItem}

                            </div>

                        </div>}


                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Faculty;
