import React from "react";
import { Card, Container } from "react-bootstrap";
import classes from "../facilities.module.css";


const Duetcs = () => {
  document.title = "Dept of CSE || DUET";
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
            <Card.Title className={classes.title}>
             DUET Computer Society (DUETCS)
            </Card.Title>
            <hr className={classes.hr_style} />
            <Card.Text className={classes.card_text}>

                Coming Soon

            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Duetcs;
