import React, {useEffect, useState} from "react";
import { Badge, Card, Container } from "react-bootstrap";
import Obidur from "../../../../assets/img/obaidur-239x300.jpg";
import Sid from "../../../../assets/img/sid.png";
import axios from "axios";
import config from "../../../../config";
import News from "../../Home/NewsAndEvents/News";
import Head from "./Head";
import SpinnerCom from "../../../Spinner/Spinner";



const HonerHead = () => {
  document.title = "Honerable Head|| CSE";

  const [heads, setHeads] = useState([]);
  const [head, setHead] = useState("");
  const [loading, setLoading] = useState(true);

  const headsData = () => {
    axios
      .get(`${config.apiUrlLaravel}/heads`)
      .then((res) => setHeads(res.data.heads))
      .catch((err) => {
        console.log(err);
        setHeads("");
      });
  };

   const headData = () => {
    axios
      .get(`${config.apiUrlLaravel}/head`)
      .then((res) => setHead(res.data.head[0]))
      .catch((err) => {
        console.log(err);
        setHead("");
      });
  };

   const loadingData=()=>{
        setLoading(false);
   }
  useEffect(async () => {
    headsData();
    headData();
    loadingData();
  }, []);


   const headItem=heads.map((item)=>{
    return (
        <Head item={item} key={Math.random()} />
    )
  });




   return loading? (<SpinnerCom />) : (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div>
            <Card.Title className="title">Message from Head</Card.Title>
            <hr className="hr_style" />

            <div className="row d-flex justify-content-center">
              <div className="col-md-12 col-lg-10 col-xl-10">
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img
                      src={config.apiUrlImage+"/"+ head.image}
                      className="rounded-circle mb-4 mb-lg-0 shadow-2"
                      alt="Head Avatar"
                      width="90"
                      height="90"
                    />
                  </div>
                  <div className="flex-grow-1 ms-4 ps-3">
                    <figure>
                      <blockquote className="blockquote mb-4">
                        <p style={{fontSize: "15px", textAlign: "justify"}}>
                          <i className="fas fa-quote-left fa-lg text-warning me-2"></i>
                          <span className="font-italic card_text">
                           {head.description}
                          </span>
                        </p>
                      </blockquote>
                      <figcaption className="blockquote-footer">
                       <span> {head.name}</span> in{" "}
                        <cite title="Source Title">Head, Dept of CSE</cite>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>

            <Card.Title className="title">
              Honorable Heads of the Department
            </Card.Title>
            <hr className="hr_style" />

            <div className="row">
              {headItem}

            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default HonerHead;
