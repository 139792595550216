import dateFormat from "dateformat";
import React, {useEffect, useState} from "react";
import {Badge, Card, ListGroup, Tab, Tabs} from "react-bootstrap";
import { Link } from "react-router-dom";
import classes from "./Notice.module.css";
import axios from "axios";
import config from "../../config";
import EventItem from "../pages/Home/NewsAndEvents/EventItem";

const Notice = (props) => {
   const [key, setKey] = useState('notice');
   const [researches, setResearches] = useState([]);

  const downloadData = () => {
    axios
        .get(`${config.apiUrlLaravel}/research`)
        .then((res) => setResearches(res.data.researches))
        .catch((err) => {
          console.log(err);
          setResearches([]);
        });
  };
  useEffect(async () => {
    downloadData();
  }, []);
  return (
      <Card  className={classes.card_size}>
          <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"

      >
        <Tab eventKey="notice" title="Notice" color="">
         <ListGroup>
             {props.notices.slice(0, 5).map((item)=> (
              <Link to={`/announcement/notices/${item.id}`}  className={classes.link_notice} key={Math.random()}>
                 <ListGroup.Item action >
                  <Badge bg="success" pill className="p-2">
                    {dateFormat(item.publication_date, "mediumDate")}
                  </Badge>
                  <span className={[classes.notice_title, "ms-2"].join(" ")}>
                     {item.title}
                   </span>
                </ListGroup.Item>
               </Link>
             ))}
         </ListGroup>
        </Tab>
        <Tab eventKey="research" title="Research Updates">
         <ListGroup>
             {researches.map((item) => (
              <Link  to={{ pathname: item.url }} target="_blank" className={classes.link_notice} key={Math.random()}>
                 <ListGroup.Item action >
                  <span className={[classes.notice_title, "ms-2"].join(" ")}>
                      <i className="fa fa-arrow-right"> </i> {item.title}
                   </span>
                </ListGroup.Item>
               </Link>
             ))}
         </ListGroup>
        </Tab>
              <Tab eventKey="event" title="Events">
        <ListGroup>
             {props.events.slice(0, 5).map((item)=> (
                <EventItem item={item} key={Math.random()} />
             ))}
         </ListGroup>
        </Tab>
      </Tabs>
      </Card>

  );
};

export default Notice;
