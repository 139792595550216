import axios from 'axios';
import React, { Component } from "react";
import { Card, Container } from "react-bootstrap";
import Achievement from "../../Home/Achievements/Achievement";
import classes from "./Around.module.css";
import config from "../../../../config";
import SpinnerCom from "../../../Spinner/Spinner";


class AchievementsOfCSE extends Component {
  state ={
    achievements: [],
     loading: true,
  }

  componentDidMount(){
    const achievementsAPI=`${config.apiUrlLaravel}/achievements`;
    axios.get(achievementsAPI)
    .then(res=> {
      this.setState({
        achievements: res.data.achievements,
        loading: false
      })
    })
    .catch(err=> console.log(err))


  }
  
  render(){
    document.title = "All Achievements of CSE || CSE";

    const achievements= this.state.achievements.map(item =>{
      return (
          <Achievement item={item} key={Math.random()} />
      )
    })

    return (
        <Container className="mt-3">
          <Card>
            <Card.Body>
              <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
                <Card.Title className={classes.title}>
                  Achievements of CSE
                </Card.Title>
                <hr className={classes.hr_style} />
                <Card.Text className={classes.card_text}>
                The Department of Computer Science and Engineering is one of the pioneering educational institutions in and outside of the university. The long list of achievements ranging from organization of premium research conferences and programming contests to personal accomplishment attained by the students and faculty members of the department is a strong proof of efficacy and dedication of the department towards achieving higher standard of education and organizational management.

                </Card.Text>
              </div>
              {this.state.loading? <SpinnerCom/> :
                  <div className="row">
                     {achievements}
                  </div>
                  }
    
              
            </Card.Body>
          </Card>
        </Container>
      );
  }
};

export default AchievementsOfCSE;
