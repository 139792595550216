import React, {useEffect, useState} from "react";
import {Card, Container, ListGroup} from "react-bootstrap";
import { withRouter } from "react-router";
import axios from "axios";
import config from "../../../config";
import Slider from "../Home/Slider";
import SpinnerCom from "../../Spinner/Spinner";

const Details = (props) => {
  document.title = "Alumni Details || CSE";
  const [batch, setBatch] = useState('');
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);

  const downloadData = () => {
    axios
        .get(`${config.apiUrlLaravel}/alumni/${props.match.params.id}`)
        .then((res) => {
            setBatch(res.data.batch);
            setStudents(res.data.batch.students);
            setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setBatch('');
        });
  };
  useEffect(async () => {
    downloadData();
  }, []);

  let studentItem = (item, index) => {
    return (
        <tr key={index}>
          <td scope="row">{item.student_id} </td>
          <td scope="row">{item.name_english} </td>
          <td scope="row">{item.mobile} </td>
          <td scope="row">{item.email} </td>
          <td scope="row">{item.current_job} </td>

            <td>
                {item.profile_link?
                     <a href={item.profile_link} target="_blank" className="btn btn-primary btn-sm">
                    Profile
                </a> : ''

                }

            </td>
        </tr>
    )
  }

  const studentsItems =students.map((item, index) => {
        return studentItem(item, index)
  });


  return loading? <SpinnerCom /> : (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div>
            <Card.Title className="title">Batch: {batch.batch_no} Session: {batch.batch_session} Passing Year: {batch.passing_year}</Card.Title>
            <hr className="hr_style" />
              <div className="row justify-content-center">
                  <div className="col-md-10 ">
                      {batch.batchsliders?   <Slider slider={batch.batchsliders}/>: '' }

                      <br/>

                      <table className="table table-striped table-bordered text-center">
                          <thead>
                          <tr>
                              <th scope="col">
                                  Student Id
                              </th>
                              <th scope="col">Name</th>
                              <th scope="col">Mobile</th>
                              <th scope="col">Email</th>
                              <th scope="col">Current Job</th>
                              <th scope="col">Profile</th>
                          </tr>
                          </thead>
                          <tbody>
                          {studentsItems? studentsItems: '' }
                          </tbody>
                      </table>
                  </div>
              </div>

          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default withRouter(Details);
