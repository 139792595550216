import React, {useEffect, useState} from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import classes from "./StudentSection.module.css";
import axios from "axios";
import config from "../../../../config";

const StudentSection = () => {
  const [summaries, setSummaries] = useState([]);

  const summaryData = () => {
    axios
        .get(`${config.apiUrlLaravel}/summary`)
        .then((res) => setSummaries(res.data.summaries))
        .catch((err) => {
          console.log(err);
          setSummaries([]);
        });
  };
  useEffect(async () => {
    summaryData();
  }, []);

  return (
      <Container data-aos="fade-up" fluid className="mt-5 mb-5">
      <Card>
        <Card.Header
          className={["text-center blue-100", classes.title_heading].join(" ")}
        >
          CSE SUMMARY INFO
        </Card.Header>
        <Card.Body>
          <Row className="text-center">
            <Col md={3}>
              <h2 className={[classes.faculty_color, classes.common].join(" ")}>
                <CountUp end={summaries[0]?summaries[0].number: '' } duration={3} redraw={true} suffix="+">
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </h2>
              <p className={classes.heading_name}>{summaries[0]?summaries[0].title: ''}</p>
            </Col>
            <Col md={2}>
              <h2 className={[classes.common, classes.ug_color].join(" ")}>
                <CountUp end={summaries[1]?summaries[1].number: '' } duration={4} redraw={true} suffix="+">
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </h2>
              <p className={classes.heading_name}>{summaries[1]?summaries[1].title: ''}</p>
            </Col>
            <Col md={2}>
              <h2
                className={[classes.common, classes.graduated_color].join(" ")}
              >
                <CountUp end={summaries[2]?summaries[2].number: ''} duration={4} redraw={true} suffix="+">
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </h2>
              <p className={classes.heading_name}>{summaries[2]?summaries[2].title: ''}</p>
            </Col>
            <Col md={2}>
              <h2 className={[classes.common, classes.pg_color].join(" ")}>
                <CountUp end={summaries[3]?summaries[3].number: ''} duration={3} redraw={true} suffix="+">
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </h2>
              <p className={classes.heading_name}>{summaries[3]?summaries[3].title: ''}</p>
            </Col>

            <Col md={3}>
              <h2 className={[classes.common, classes.pg_award_color].join(" ")}>
                <CountUp end={summaries[4]?summaries[4].number: ''} duration={3} redraw={true} suffix="+">
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </h2>
              <p className={classes.heading_name}>{summaries[4]?summaries[4].title: ''}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
    
  );
};

export default StudentSection;
