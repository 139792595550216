import dateFormat from "dateformat";
import React, { useState } from "react";
import {Badge, Button,ListGroup, Modal} from "react-bootstrap";
import { Link } from "react-router-dom";
import classes from "./News.module.css";
import config from "../../../../config";

const EventItem = (props) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
      <div>
          <Link to="#" onClick={handleShow} className={classes.news_element_title}>
              <ListGroup.Item action>
                  <Badge bg="danger" pill className="p-2">
                      {dateFormat(props.item.publication_date, "mediumDate")}
                  </Badge>
                  <span className={[classes.notice_title, "ms-2"].join(" ")}>
                         {props.item.title}
               </span>
              </ListGroup.Item>
          </Link>
          <Modal show={show} onHide={handleClose}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
           >
               <Modal.Header closeButton>
                   <Modal.Title>{props.item.title}</Modal.Title>
               </Modal.Header>

               <Modal.Body>
                   <img src={config.apiUrlImage + "/" + props.item.image} alt="news" width="100%"/>

                   <i> Publication: </i> <b>{dateFormat(props.item.publication_date, "mediumDate")}</b>


                   <br/>
                   <div dangerouslySetInnerHTML={{ __html: props.item.description }}></div>

               </Modal.Body>
               <Modal.Footer>
                   <Button variant="secondary" onClick={handleClose}>
                       Close
                   </Button>
               </Modal.Footer>
           </Modal>
      </div>
  );
};

export default EventItem;
