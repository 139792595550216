import React, { Component } from "react";
import { Card, Container,ThemeProvider } from "react-bootstrap";
import classes from './AboutOfCSE.module.css';

class AboutOfCSE extends Component {
  render() {
    document.title = "About of CSE || CSE";
    return (
        <div className="container container-xl container-xxl mt-3">

          <Card>
          <Card.Body>
            <div data-aos="fade-down">
              <Card.Title  className={classes.title}>History of CSE</Card.Title>
              <hr  className={classes.hr_style}/>

              <Card.Text  className={["text-justify", classes.card_text].join(' ')}>
                Computer Engineers has an important role in the world of technology and in the development and digitization process of the nation. To meet such national demand, the Computer Science and Engineering (CSE) department is introduced in Dhaka University of Engineering & Technology, Gazipur (DUET) in 1999 with 60 Undergraduate students as a project running in all the erstwhile BITs. Since then, around 840 Undergraduate students (of 14 batches) Graduated from this Department. The Graduates are working in different government and private organizations as well as in teaching professions in home and abroad. Currently, CSE Department is taking 120 students at the Undergraduate level in each session. In 211 (Session: 210-11), CSE Department has stepped into wider arena by introducing Master of Science in Computer Science and Engineering Degree. From 2015 (Session: 2014-15) the Department is offering Ph. D admission and currently the Department has a bunch of Ph. D students. As its subject is always updating, we try to introduce our students with the latest technologies and software, so that they would not face any problem in the job market. The Department of CSE devotes considerable effort and resources for the research and development (R&D) purpose. Accordingly, the department consists of a number well- equipped laboratory both for software and hardware related R&D. The list of laboratories includes- Software lab, Network Lab, Multimedia Lab, Digital Electronics & Microprocessor Lab. Besides, a 60-seated Distance Learning Theater is established by the HEQUEP (Higher Education Quality Enhanced Project) fund, which is mainly used for research seminar and video-conferencing with research experts both from home and abroad. Students of CSE have been operating a computer club named DUET Computer Society (DUETCS), which arranges seminar, programming contest, training program and publishes IT magazines.
              </Card.Text>
              <Card.Text className={["text-justify", classes.card_text].join(' ')}>
                In total, the department has 32 active teachers, 10 of whom have
                already earned their PhD degrees. Currently,10 faculty members
                are on leave for pursuing their PhDs from different universities
                of the world.
              </Card.Text>

              </div>

            <div data-aos="fade-up-right">
              <Card.Title  className={classes.title}>Vision</Card.Title>
              <hr className={classes.hr_style} />
              <Card.Text className={["text-justify", classes.card_text].join(' ')}>
                To become a center of excellence in computer science and
                engineering through quality teaching, learning and
                transformation of research that contributes to the betterment of
                society.
              </Card.Text>
            </div>

            <div data-aos="fade-up-left">
              <Card.Title  className={classes.title}>Mission</Card.Title>
              <hr className={classes.hr_style}/>
              <ol>
                <li>
                    <p className={["text-justify", classes.card_text].join(' ')}>
                    To produce competent graduates with sound theoretical
                  knowledge and practical skills in the fields of computer
                  science and engineering through a true outcome-based
                  education.
                    </p>

                </li>
                <li>
                    <p className={["text-justify", classes.card_text].join(' ')}>
                    To promote quality research that helps in solving real-world
                  problems, thereby contributing to the betterment of humanity.
                    </p>

                </li>
                <li>
                 <p className={["text-justify", classes.card_text].join(' ')}> To disseminate values and ideals among the students for
                  upholding professional ethics and morals.</p>
                </li>
                <li>
                    <p className={["text-justify", classes.card_text].join(' ')}>  To facilitate academia-industry-government collaboration for
                  building the potentiality of life-long learning and solving
                  new challenges.</p>

                </li>
              </ol>
            </div>
          </Card.Body>
        </Card>
        </div>
    );
  }
}

export default AboutOfCSE;
