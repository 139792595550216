import React from "react";
import { Card, Container } from "react-bootstrap";
import newImage from "../../../../assets/new.jpeg";
import oldImage from "../../../../assets/old-ac.jpg";
import classes from "./Around.module.css";

const AroundCSE = () => {
  document.title = "Getting Around of CSE || CSE";
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
            <Card.Title className={classes.title}>
              Getting Around of CSE
            </Card.Title>
            <hr className={classes.hr_style} />
            <Card.Text className={classes.card_text}>
              The Department of Computer Science and Engineering is located in
              the Old Academic Building and Shaheed Syed Nazrul Islam Academic
              Building.
            </Card.Text>

            <div className="row text-center">
              <div className="col-md-6">
                <div className={classes.img_container}>
                  <img
                    src={oldImage}
                    alt="Avatar"
                    className={[classes.image, "rounded"].join(" ")}
                  />
                  <div className={classes.overlay}>Old Academic Building</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className={classes.img_container}>
                  <img
                    src={newImage}
                    alt="Avatar"
                    className={[classes.image, "rounded"].join(" ")}
                  />
                  <div className={classes.overlay}>
                    Shaheed Syed Nazrul Islam Academic Building
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div data-aos="fade-left">
            <Card.Title className={classes.title}>
              Old Academic Building
            </Card.Title>
            <hr className={classes.hr_style} />

            <div className="row justify-content-center">
            <Card.Text className={classes.card_text}>The room numbers in Old Academic Building and the corresponding function of the rooms are listed below:</Card.Text>
              <div className="col-md-8 ">
                <table className="table table-hover">
                   
                  <thead>
                    <tr>
                      <th scope="col" width="20%" >Room No.</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">225</th>
                      <td>Networking Lab</td>
                    </tr>
                    <tr>
                      <th scope="row">320 (A)</th>
                      <td>Dr.  Mohammad Abdur Rouf, Professor, Dept. of CSE</td>
                    </tr>
                    <tr>
                      <th scope="row">320 (B)</th>
                      <td colSpan="2">Mr. Abdul Aziz, Assistant Section Officer</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div data-aos="fade-right">
            <Card.Title className={classes.title}>
              Shaheed Syed Nazrul Islam Academic Building
            </Card.Title>
            <hr className={classes.hr_style} />

            <div className="row justify-content-center">
            <Card.Text className={classes.card_text}>The room numbers in  Shaheed Syed Nazrul Islam Academic
              Building and the corresponding function of the rooms are listed below:</Card.Text>
              <div className="col-md-8 ">
                <table className="table table-hover">
                   
                  <thead>
                    <tr>
                      <th scope="col" width="20%" >Room No.</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">7003</th>
                      <td>Dr. Md. Jakirul Islam, Assistant Professor, Dept. of CSE</td>
                    </tr>
                    <tr>
                      <th scope="row">7004</th>
                      <td>Mr. Md. Zakir Hossain, Assistant Professor, Dept. of CSE</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AroundCSE;
