import React from "react";
import { Card, Container } from "react-bootstrap";
import oldImage from "../../../../assets/old-ac.jpg";
import classes from "./Around.module.css";

const Collaboration = () => {
  document.title = "Getting Around of CSE || CSE";
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
            <Card.Title className={classes.title}>
              Collaboration With CCNA
            </Card.Title>
            <hr className={classes.hr_style} />
            <Card.Text className={classes.card_text}>
              The Department of Computer Science and Engineering is located in
              the Old Academic Building and Shaheed Syed Nazrul Islam Academic
              Building.
            </Card.Text>

            <div className="row ">
              <div className="col-md-6">
                <div className={classes.img_container}>
                  <img
                    src={oldImage}
                    alt="Avatar"
                    className={[classes.image, "rounded"].join(" ")}
                  />
                  <div className={classes.overlay}>CNNA Course</div>
                </div>
              </div>
              <div className="col-md-6">
                <ul className="list-group">
                  <li className="list-group-item">Cras justo odio</li>
                  <li className="list-group-item">Dapibus ac facilisis in</li>
                  <li className="list-group-item">Morbi leo risus</li>
                  <li className="list-group-item">Porta ac consectetur ac</li>
                  <li className="list-group-item">Vestibulum at eros</li>
                </ul>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Collaboration;
