import React from "react";
import { Card, Container } from "react-bootstrap";
import classes from "../facilities.module.css";


const Others = () => {
  document.title = "Dept of CSE || DUET";
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
            <Card.Title className={classes.title}>
             Others Information
            </Card.Title>
            <hr className={classes.hr_style} />
            <Card.Text className={classes.card_text}>
           <strong>Description: </strong> This laboratory has 32 high performance workstations, 1 multimedia projector, 1 printer, 1 scanner, etc.
           <br />
           <strong>Location: </strong> 1nd Floor (Room Number: 325)


            </Card.Text>

            <div className="row text-center justify-content-center">
              <div className="col-md-8">
                <div className={classes.img_container}>
                  <img
                    src="/images/lab/networklab.jpg"
                    alt="Avatar"
                    className={[classes.image, "rounded"].join(" ")}
                  />
                  <div className={classes.overlay}>Network Lab</div>
                </div>
              </div>

            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Others;
