import React, {useEffect, useState} from "react";
import { Card, Container, ListGroup } from "react-bootstrap";

import  oldImg from '../../../assets/old-ac.jpg';
import {Link} from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import './alumni.css';
import SpinnerCom from "../../Spinner/Spinner";

const Alumni = () => {
  document.title = "Alumni || CSE";
  const [alumni, setAlumni] = useState([]);
   const [loading, setLoading] = useState(true);

  const downloadData = () => {
    axios
        .get(`${config.apiUrlLaravel}/alumni`)
        .then((res) => {
            setAlumni(res.data.batches);
            setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAlumni([]);
        });
  };
  useEffect(async () => {
    downloadData();
  }, []);


  let downloadItem = (item, index) => {

    return (
        <div className="col-md-4" key={index}>
            <Link to={`/alumni/${item.id}`} className="links">
          <Card className="m-2">
            <Card.Img variant="top"
                      src={item.batchsliders[0] ? config.apiUrlImage + "/" + item.batchsliders[0].image : oldImg}/>
            <Card.Body className="text-center">

                  Batch: {item.batch_no}

              <Card.Text>
                Session: {item.batch_session} <br/>
                Passing Year :{item.passing_year}
              </Card.Text>
            </Card.Body>
          </Card>
            </Link>
        </div>
    )
  }

  const downloadItems = alumni.map((item, index) => {
    return downloadItem(item, index)
  });
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
            <Card.Title className="title">Alumni</Card.Title>
            <hr className="hr_style" />
            {loading? <SpinnerCom />:
            <div className="row">
                {downloadItems}
            </div>
                }
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Alumni;
