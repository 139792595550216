import React, {useEffect, useState} from "react";
import { Card, Container } from "react-bootstrap";
import axios from "axios";
import config from "../../../../config";
import SpinnerCom from "../../../Spinner/Spinner";

const ACalander = () => {
  document.title = "Academic Calendar || CSE";

  const [ugcalendear, setUgcalendear] = useState([]);
  const [pgcalendear, setPgcalendear] = useState([]);
  const [loading, setLoading] = useState(true);

  const downloadDataUG = () => {
    axios
        .get(`${config.apiUrlLaravel}/academics/UG_AC`)
        .then((res) => setUgcalendear(res.data.academics))
        .catch((err) => {
          console.log(err);
          setUgcalendear([]);
        });
  };

  const downloadDataPG = () => {
    axios
        .get(`${config.apiUrlLaravel}/academics/PG_AC`)
        .then((res) => {
            setPgcalendear(res.data.academics);
             setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPgcalendear([]);
        });
  };
  useEffect(async () => {
    downloadDataUG();
    downloadDataPG();

  }, []);

  let downloadItem = (item, index) => {
    return (
        <tr key={Math.random()}>
          <th scope="row">{index + 1} </th>
          <td className="links">
             <a  href={config.apiUrlImage + "/" + item.file} target="_blank"  rel="noopener noreferrer" download >{item.title}</a>
          </td>
              <th>

            <a href={config.apiUrlImage + "/" + item.file} target="_blank"  rel="noopener noreferrer" className="btn btn-primary btn-sm" download>
              Download
            </a>
          </th>
        </tr>
    )
  }

  const downloadItemsUG = ugcalendear.map((item, index) => {
    return downloadItem(item, index)
  });

  const downloadItemsPG = pgcalendear.map((item, index) => {
    return downloadItem(item, index)
  });


  return loading? <SpinnerCom/>:(
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div>
            <Card.Title className="title">Undergraduate Academic Calendar</Card.Title>
            <hr className="hr_style" />

              <div className="row justify-content-center">
                  <div className="col-md-10 ">
                      <table className="table table-striped table-bordered text-center">
                          <thead>
                          <tr>
                              <th scope="col" width="20%">
                                  SL
                              </th>
                              <th scope="col">Name of Calendar</th>
                              <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          {downloadItemsUG}

                          </tbody>
                      </table>
                  </div>
              </div>

            <Card.Title className="title">Postgraduate Academic Calendar</Card.Title>
            <hr className="hr_style" />

              <div className="row justify-content-center">
                  <div className="col-md-10 ">
                      <table className="table table-striped table-bordered text-center">
                          <thead>
                          <tr>
                              <th scope="col" width="20%">
                                  SL
                              </th>
                              <th scope="col">Name of Calendar</th>
                              <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          {downloadItemsPG}

                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ACalander;
