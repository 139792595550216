import React, {useEffect, useState} from "react";
import { Badge, Card, Container } from "react-bootstrap";
import avater from "../../../../assets/img/avater.jpg";
import axios from "axios";
import config from "../../../../config";
import SpinnerCom from "../../../Spinner/Spinner";

const Staff = () => {
  document.title = "Staffs || CSE";


  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(true);
   const peopleData = () => {
    axios
      .get(`${config.apiUrlLaravel}/people/staff`)
      .then((res) => {
          setPeople(res.data.people);
          setLoading(false);

      })
      .catch((err) => {
        console.log(err);
        setPeople([]);
      });
  };
     useEffect(async () => {
    peopleData();

  }, []);

     const peopleItem=people.map((item)=>{
    return (
       <div className="col-md-6" key={Math.random()}>
                 <Card className="shadow p-2 m-2 ">
                <div className="row">
                  <div className="col-md-4">
                    <img
                      src={item.image? config.apiUrlImage+"/"+ item.image : avater }
                      height="120px"
                      width="100px"
                      alt=""
                      className="rounded mx-auto d-block"
                    />
                  </div>
                  <div className="col-md-8 mt-3">
                    <h6>{item.name_english}</h6>
                    <h6>({item.name_bangla})</h6>
                    <p className="m-0">{item.designation.name}</p>
                   <strong><i className="fa  fa-phone-square" style={{marginRight: "10px"}}></i></strong>{item.mobile} <br />
                   <strong><i className="fa  fa-envelope-square" style={{marginRight: "10px"}}></i></strong> {item.email}

                  </div>
                </div>
                 </Card>
              </div>
    )
  });
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div>
            <Card.Title className="title">Staffs List</Card.Title>
            <hr className="hr_style" />

                    {loading ? <SpinnerCom/> :
                    <div className="row">
                        {peopleItem}
                    </div>
                    }
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Staff;
