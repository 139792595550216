
import config from "../../../../config";
import {Card} from "react-bootstrap";
import React from "react";
import Avater from '../../../../assets/img/avater.jpg';
import dateFormat from "dateformat";

const Head = (props) => {
    return(
        <div className="col-md-6 ">
        <Card className={`shadow p-2 m-2 ${props.item.end_date?'': 'border-danger'}`}>
            <div className="row">
                <div className="col-md-5">
                    <img
                        src={props.item.image?config.apiUrlImage+"/"+ props.item.image: Avater}
                        height="120px"
                        width="100px"
                        alt=""
                        className="rounded mx-auto d-block"
                    />
                </div>
                <div className="col-md-7 mt-5">
                    <h6>{props.item.name}</h6>
                    <strong> Service Period: </strong>
                    <p> { dateFormat(props.item.start_date, "mediumDate")} to {props.item.end_date? dateFormat(props.item.end_date, "mediumDate"): 'Continue' }</p>
                </div>
            </div>
        </Card>
    </div>
    );

}

export default  Head;