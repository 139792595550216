import React, {useEffect, useState} from "react";
import {Button, Card, Container, ListGroup} from "react-bootstrap";
import axios from "axios";
import config from "../../../../config";
import SpinnerCom from "../../../Spinner/Spinner";
import GalleryItem from "./GalleryItem";

const Gallery = () => {
  document.title = "Gallery || CSE";
  const [galleries, setGalleries] = useState([]);
   const [loading, setLoading] = useState(true);

   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



   const handleClick=(item)=>{
       console.log(item)
    }

  const downloadData = () => {
    axios
        .get(`${config.apiUrlLaravel}/gallery`)
        .then((res) => {
            setGalleries(res.data.galleries);
            setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setGalleries([]);
        });
  };
  useEffect(async () => {
    downloadData();
  }, []);


  let downloadItem = (item, index) => {

    return (
        <GalleryItem item={item} key={Math.random()} />
    )
  }

  const downloadItems = galleries.map((item, index) => {
    return downloadItem(item, index)
  });

  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
            <Card.Title className="title">Gallery</Card.Title>
            <hr className="hr_style" />
            {loading? <SpinnerCom />:
            <div className="row">
                {downloadItems}
            </div>
                }
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Gallery;
