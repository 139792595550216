import React, {useEffect, useState} from "react";
import { Card, Container } from "react-bootstrap";
import classes from "../facilities.module.css";
import axios from "axios";
import config from "../../../../config";
import SpinnerCom from "../../../Spinner/Spinner";


const Thesis = () => {
  document.title = "Dept of CSE || DUET";


  const [classrooms, setClassrooms] = useState([]);
    const [loading, setLoading] = useState(true);

    const classroomData = () => {
        axios
            .get(`${config.apiUrlLaravel}/facilities/Thesis`)
            .then((res) => {
                setClassrooms(res.data.facilities);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setClassrooms([]);
            });
    };
    useEffect(async () => {
        classroomData();
    }, []);

    const itemClass=(item, index)=>{
        return(
            <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400" key={Math.random()}>
            <Card.Title className={classes.title}>
                {`${index+1}. ${item.title}`}
            </Card.Title>
            <hr className={classes.hr_style} />
            <Card.Text className={classes.card_text}>
           <strong>Description: </strong> {item.description}
           <br />
           <strong>Location: </strong> {item.location}


            </Card.Text>

            <div className="row text-center justify-content-center">
              <div className="col-md-8">
                <div className={classes.img_container}>
                  <img
                    src={config.apiUrlImage + "/" + item.image}
                    alt="Avatar"
                    className={[classes.image, "rounded"].join(" ")}
                  />
                  <div className={classes.overlay}>{item.title}</div>
                </div>
              </div>

            </div>
          </div>

        )
    }

    const classroomsItems=classrooms.map((item, index)=>{
        return itemClass(item,index);
    })

  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          {loading? <SpinnerCom/>:classroomsItems }
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Thesis;

