import React from "react";
import { Card, Container } from "react-bootstrap";
import Notice from './Notice';

const Notices = () => {
  document.title = "Notices || CSE";
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div>
            <Card.Title className="title">All Notices</Card.Title>
            <hr className="hr_style" />
          
           <Notice />
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Notices;
