import Aos from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import React,  { useEffect }from 'react';
import {
  BrowserRouter as Router, Redirect, Route,
  Switch, useLocation,
  withRouter
} from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import AboutOfCSE from "./components/pages/About/AboutOfCSE";
import AchievementsOfCSE from "./components/pages/About/Achievements";
import AchievemnetDetail from "./components/pages/About/Achievements/AchievemnetDetail/AchievemnetDetail";
import AroundCSE from "./components/pages/About/AroundCSE";
import Collaboration from "./components/pages/About/Collaboration";
import DownloadForm from "./components/pages/ Facilities/DownloadsForm";
import ACalander from "./components/pages/Academic/ACalander/ACalander";
import Admission from "./components/pages/Academic/Admission/Admission";
import CRoutine from "./components/pages/Academic/CRoutine/CRoutine";
import PG from "./components/pages/Academic/PG/PG";
import UG from "./components/pages/Academic/UG/UG";
import Alumni from "./components/pages/Alumni/Alumni";
import NewsEvents from "./components/pages/Announcement/NewsEvents/NewsEvents";
import Notices from "./components/pages/Announcement/Notices/Notices";
import Home from "./components/pages/Home/Home";
import Faculty from "./components/pages/People/Faculty/Faculty";
import HonerHead from "./components/pages/People/HonerHead/HonerHead";
import Officer from "./components/pages/People/Officer/Officer";
import Staff from "./components/pages/People/Staff/Staff";
import Publications from "./components/pages/Research/Publications/Publications";
import NoticeDetail from "./components/pages/Announcement/Notices/NoticeDetail";
import Labs from "./components/pages/ Facilities/Labs";
import Classrooms from "./components/pages/ Facilities/Classrooms/classrooms";
import Duetcs from "./components/pages/ Facilities/Duetcs/duetcs";
import Others from "./components/pages/ Facilities/Others/Others";
import ClassRep from "./components/pages/Academic/ClassRep/ClassRep";
import MajorResearchAreas from "./components/pages/Research/MajorResearchAreas/MajorResearchAreas";
import ExpertsResearch from "./components/pages/Research/ExpertsResearch/ExpertsResearch";
import Details from "./components/pages/Alumni/Details";
import Cisco from "./components/pages/Others/Cisco";
import Gallery from "./components/pages/Others/Gallery/Gallery";
import Thesis from "./components/pages/ Facilities/Thesis/Thesis";
import Crts from "./components/pages/ Facilities/CRTS/Crts";
import Training from "./components/pages/Others/Training/Training";



function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  });

  return (
    <Router>
      <ScrollToTop>
      <Header />
      <div className="main">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about-of-cse">
            <AboutOfCSE />
          </Route>
          <Route exact path="/around-cse">
            <AroundCSE />
          </Route>
          <Route exact path="/achievements">
            <AchievementsOfCSE />
          </Route>

          <Route exact path="/achievement/:id">
            <AchievemnetDetail />
          </Route>

            <Route exact path="/facilities/classrooms">
            <Classrooms />
          </Route>
          <Route exact path="/facilities/labs">
            <Labs />
          </Route>
           <Route exact path="/facilities/duetcs">
            <Duetcs />
          </Route>
            <Route exact path="/facilities/others">
            <Others />
          </Route>
            <Route exact path="/facilities/thesis_lab">
            <Thesis />
          </Route>
           <Route exact path="/facilities/crts">
            <Crts />
          </Route>

          <Route exact path="/collaboration">
            <Collaboration />
          </Route>
          <Route exact path="/downloads-form">
            <DownloadForm />
          </Route>
          <Route exact path="/academic/ug-curriculum">
            <UG />
          </Route>
          <Route exact path="/academic/pg-curriculum">
            <PG />
          </Route>
          <Route exact path="/academic/class-routine">
            <CRoutine />
          </Route>
          <Route exact path="/academic/calender">
            <ACalander />
          </Route>
          <Route exact path="/academic/admission">
            <Admission />
          </Route>

          <Route exact path="/academic/classrep">
            <ClassRep />
          </Route>
          <Route exact path="/people/honerable-heads">
            <HonerHead />
          </Route>
          <Route exact path="/people/faculty-members">
            <Faculty />
          </Route>
          <Route exact path="/people/officers">
            <Officer/>
          </Route>
          <Route exact path="/people/staffs">
            <Staff/>
          </Route>
          <Route exact path="/research/publications">
            <Publications/>
          </Route>
            <Route exact path="/research/MajorResearchAreas">
            <MajorResearchAreas/>
          </Route>
            <Route exact path="/research/ExpertsResearch">
            <ExpertsResearch/>
          </Route>
          <Route exact path="/announcement/notices">
            <Notices/>
          </Route>
            <Route exact path="/announcement/notices/:id">
            <NoticeDetail />
          </Route>

          <Route exact path="/announcement/newsandevents">
            <NewsEvents/>
          </Route>
          <Route exact path="/others/cisco">
            <Cisco/>
          </Route>
           <Route exact path="/gallery">
            <Gallery/>
          </Route>
             <Route exact path="/training">
          <Training />
          </Route>
          <Route exact path="/alumni">
            <Alumni/>
          </Route>
            <Route exact path="/alumni/:id">
            <Details />
          </Route>
          <Redirect to="/" />
        </Switch>
      </div>
      <Footer />
      </ScrollToTop>
    </Router>
   
  );
  
};

export default App;
