import React, {useState} from "react";
import {Button, Card, Container, ListGroup, Modal, Col, Carousel} from "react-bootstrap";
import {Link} from "react-router-dom";
import config from "../../../../config";
import  './Gallery.css';


const GalleryItem = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const images = props.item.image.split("|");

    return (
        <Col md={4} >
            <Link to="#" onClick={handleShow} className="links">
            <Card className="m-2 h-100">
                <Card.Img variant="top" className="card-img-top" src={config.apiUrlImage + "/" + images[0]}/>
                <Card.Body>
                    <Card.Text>

                        {props.item.title}

                    </Card.Text>
                </Card.Body>
            </Card>
                </Link>

            <Modal show={show} onHide={handleClose}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.item.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Carousel>
                        {images.map((item) => (
                            <Carousel.Item key={item.image}>
                                <img
                                    className="img-slider img-fluid"
                                    //src={item.image}
                                    src={config.apiUrlImage + "/" + item}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Col>


    );
};

export default GalleryItem;
