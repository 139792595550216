import React, {useEffect, useState} from "react";
import {Card, Container, ListGroup} from "react-bootstrap";
import classes from "./Around.module.css";
import axios from "axios";
import config from "../../../../config";
import {Link} from "react-router-dom";
import SpinnerCom from "../../../Spinner/Spinner";

const DownloadForm = () => {
  document.title = "Download Form|| CSE";

  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(true);

  const downloadData = () => {
    axios
        .get(`${config.apiUrlLaravel}/downloads`)
        .then((res) => {
          setDownloads(res.data.downloads);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setDownloads([]);
        });
  };
  useEffect(async () => {
    downloadData();
  }, []);

  let downloadItem = (item, index) => {
    return (
        <tr key={Math.random()}>
          <th scope="row">{index + 1} </th>
          <td>{item.title}</td>
          <th>
            <a href={config.apiUrlImage + "/" + item.file} target="_blank" className="btn btn-primary btn-sm" download>
              Download
            </a>
          </th>
        </tr>
    )
  }

  const downloadItems = downloads.map((item, index) => {
    return downloadItem(item, index)
  });




  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div data-aos="fade-left">
            <Card.Title className={classes.title}>Download Form</Card.Title>
            <hr className={classes.hr_style} />

            {loading? <SpinnerCom />:

            <div className="row justify-content-center">
              <div className="col-md-8 ">
                <table className="table table-striped table-bordered text-center">
                  <thead>
                    <tr>
                      <th scope="col" width="20%">
                        SL
                      </th>
                      <th scope="col">Name of Form</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {downloadItems}

                  </tbody>
                </table>
              </div>
            </div>
                }
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default DownloadForm;
