import React from "react";
import { Card, Container } from "react-bootstrap";

const MajorResearchAreas = () => {
  document.title = "Major Research Areas || CSE";
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
            <div>
                <Card.Title className="text-center">Major Research Areas</Card.Title>
            <hr className="hr_style" />
            </div>
            Coming Soon
          {/*<div data-aos="fade-right">*/}
          {/*  <Card.Title  className="title ">Computing & Software Engineering</Card.Title>*/}
          {/*  <hr className="hr_style" />*/}

          {/*  <div className="row">*/}
          {/*    <div className="col-md-5">*/}
          {/*        <img*/}
          {/*          src="/images/lab/networklab.jpg"*/}
          {/*          alt="Avatar"*/}
          {/*          className="rounded mx-auto d-block"*/}
          {/*          style={{width: "400px", height: "300px"}}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*      <div className="col-md-7 text-center d-flex align-items-center justify-content-center">*/}
          {/*        Lorem Ipsum is simply dummy text the indusype and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.*/}
          {/*      </div>*/}

          {/*  </div>*/}
          {/*</div>*/}


          {/*<div data-aos="fade-left">*/}
          {/*  <Card.Title  className="title">Networking & IoT</Card.Title>*/}
          {/*  <hr className="hr_style" />*/}

          {/*  <div className="row">*/}

          {/*      <div className="col-md-7 text-center d-flex align-items-center justify-content-center">*/}
          {/*        Lorem Ipsum is simply dummy text the indusype and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.*/}
          {/*      </div>*/}
          {/*    <div className="col-md-5">*/}
          {/*        <img*/}
          {/*          src="/images/lab/networklab.jpg"*/}
          {/*          alt="Avatar"*/}
          {/*          className="rounded mx-auto d-block"*/}
          {/*          style={{width: "400px", height: "300px"}}*/}
          {/*        />*/}
          {/*      </div>*/}

          {/*  </div>*/}
          {/*</div>*/}


        </Card.Body>
      </Card>
    </Container>
  );
};

export default MajorResearchAreas;