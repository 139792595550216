import React from "react";
import { Card, Container } from "react-bootstrap";

const ExpertsResearch = () => {
  document.title = "Experts & Research Fields || CSE";
  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <div>
            <Card.Title className="title">Experts & Research Fields</Card.Title>
            <hr className="hr_style" />
           

           
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ExpertsResearch;
