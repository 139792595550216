import React, {useEffect, useState} from "react";
import { Card } from "react-bootstrap";
import classes from "./Head.module.css";
import axios from "axios";
import config from "../../config";

const Head = () => {
   const [head, setHead] = useState("");

  const headData = () => {
    axios
        .get(`${config.apiUrlLaravel}/head`)
        .then((res) => setHead(res.data.head[0]))
        .catch((err) => {
          console.log(err);
          setHead("");
        });
  };
  useEffect(async () => {
    headData();
  }, []);

  return (
    <div>
      <Card className="text-center card_height">
        <div className="text-center">
          <img
              src={config.apiUrlImage + "/" + head.image}
              width="170"
              height="200"
              className="rounded-circle"
              alt="This Head"
          />
        </div>

        <Card.Body>
          <Card.Title className={[classes.head_title, "text-info"].join(" ")}>
            Message Head
          </Card.Title>
          <Card.Text className={classes.message_info}>
            {head.description}

          </Card.Text>
          <p className="mb-0">Best wishes, </p>
          <p>
            {" "}
            <i className={classes.head_name}>{head.name}</i>
          </p>

          <br/>
          <br/>
          <br/>
          <br/>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Head;
