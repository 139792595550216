import axios from "axios";
import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Achievements from "./Achievements";
import classes from './Home.module.css';
import NewsAndEvents from "./NewsAndEvents";
import Slider from "./Slider";
import StudentSection from "./StudentSection";
import config from "../../../config";
import Notice from "../../Notice";
import Head from "../../Head";
import SpinnerCom from "../../Spinner/Spinner";
import Marquee from "react-fast-marquee";
import  ncim from '../../../assets/NCIMlogo.png';

class Home extends Component {
  state = {
    loading: true,
    notices: [],
    slider: [],
    newsEvents: [],
    achievements: [],
  };

  fetchData = () => {
    //const noticesAPI = "http://localhost:3001/notices";
   // const noticesAPI = "http://127.0.0.1:8000/api/notices";
    const noticesAPI = `${config.apiUrlLaravel}/notices/`;
    //const sliderAPI = `${config.apiUrlLocal}/slider`;
    const sliderAPI = `${config.apiUrlLaravel}/sliders/`;
    //const newsAPI = "http://localhost:3001/newsevents";
    const newsAPI =  `${config.apiUrlLaravel}/newsevents/`;
    //const achievementsAPI = "http://localhost:3001/achievements";
    const achievementsAPI =  `${config.apiUrlLaravel}/achievements/`;

    const noticesData = axios.get(noticesAPI);
    const sliderData = axios.get(sliderAPI);
    const newsData = axios.get(newsAPI);
    const achievementsData = axios.get(achievementsAPI);



    axios
      .all([noticesData, sliderData, newsData, achievementsData])
      .then(
        axios.spread((...allData) => {
          //const notices = allData[0].data;
          const notices = allData[0].data.notices;
          const slider = allData[1].data.sliders;
          const newsevents = allData[2].data.newsevents;
          const achievements = allData[3].data.achievements;


          this.setState({
           // notices: notices,
            notices: notices,
            slider: slider,
            newsEvents: newsevents,
            achievements: achievements,
              loading: false
          });
        })
      )
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    document.title = "Dept of CSE || DUET";

    return this.state.loading? (<SpinnerCom />) : (
              <div className="container-xxl">

                <div className="row">
                    {/*<div className="col-md-12">*/}
                    {/*      <UpdateNotice notices={this.state.notices}/>*/}
                    {/*</div>*/}

                    <div className="col-md-8">
                         <Slider slider={this.state.slider}/>
                    </div>
                     <div className="col-md-4">
                         <Notice  notices={this.state.notices} events={this.state.newsEvents} />
                    </div>

                </div>
                <div className="mt-2">
                      <Marquee gradientWidth={10} >
                      <a href="https://confncim.com/" target="_blank"><img src={ncim} alt="NCIM 2023"/></a>
                  </Marquee>
                </div>

                  <div className="row mt-2">
                      <div className="col-md-5">
                          <Head/>
                      </div>

                      <div className="col-md-7">
                          <Card className="card_height">
                              <Card.Header className={classes.title}>Welcome to CSE</Card.Header>
                              <Card.Body>
                                  <Card.Text className={["text-justify", classes.card_text].join(' ')}>
                                      In order to prepare potential and productive persons in industry, academia, as
                                      well as
                                      in government sector, in the undergraduate and postgraduate programs of the
                                      department
                                      of CSE of DUET, an excellent environment of teaching, learning, and research in
                                      computing and IT has been maintained from the very past. In particular, to perform
                                      world-class research in selected areas.
                                  </Card.Text>


                                  <div data-aos="fade-up">
                                      <Card.Title className={classes.title_new}>Vision</Card.Title>
                                      <hr className={classes.hr_style}/>
                                      <Card.Text className={["text-justify", classes.card_text_new].join(' ')}>
                                          To become a center of excellence in computer science and
                                          engineering through quality teaching, learning and
                                          transformation of research that contributes to the betterment of
                                          society.
                                      </Card.Text>
                                  </div>

                                  <div data-aos="fade-up">
                                      <Card.Title className={classes.title_new}>Mission</Card.Title>
                                      <hr className={classes.hr_style}/>
                                      <ol>
                                          <li>
                                              <p className={["text-justify", classes.card_text_new].join(' ')}>
                                                  To produce competent graduates with sound theoretical
                                                  knowledge and practical skills in the fields of computer
                                                  science and engineering through a true outcome-based
                                                  education.
                                              </p>

                                          </li>
                                          <li>
                                              <p className={["text-justify", classes.card_text_new].join(' ')}>
                                                  To promote quality research that helps in solving real-world
                                                  problems, thereby contributing to the betterment of humanity.
                                              </p>

                                          </li>
                                          <li>
                                              <p className={["text-justify", classes.card_text_new].join(' ')}> To
                                                  disseminate
                                                  values and ideals among the students for
                                                  upholding professional ethics and morals.</p>
                                          </li>
                                          <li>
                                              <p className={["text-justify", classes.card_text_new].join(' ')}> To
                                                  facilitate
                                                  academia-industry-government collaboration for
                                                  building the potentiality of life-long learning and solving
                                                  new challenges.</p>

                                          </li>
                                      </ol>
                                  </div>

                              </Card.Body>
                          </Card>
                      </div>

                  </div>

                  <div className="row">
                         <StudentSection/>
                  </div>


                  <div className="row m-0 p-0 mb-4">
                      <div className="col-md-6 m-0 p-0">
                          <NewsAndEvents newsEvents={this.state.newsEvents}/>
                      </div>
                      <div className="col-md-6 m-0 p-0">
                          <Achievements achievements={this.state.achievements}/>
                      </div>
                  </div>

              </div>
          );
      }

}

export default Home;
